import React, { useState, useEffect, useRef } from "react";
import Main from "./components/Main/Main";
import "./index.css";

const Application = () => {
  const [ws, setWs] = useState(null);
  const [isWsConnected, setIsWsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const isStopped = useRef(null);
  const source = useRef(null);

  useEffect(() => {
    const closeWs = () => {
      if (ws) {
        ws.close();
      }
    };
    window.addEventListener("beforeunload", () => {
      closeWs();
    });

    return () => {
      window.removeEventListener("beforeunload", () => {
        closeWs();
      });
    };
  }, [ws]);

  const connectWebSocket = () => {
    if (!ws || ws.readyState === WebSocket.CLOSED) {
      const newWs = new WebSocket(`${process.env.REACT_APP_WS_ENDPOINT}`);

      newWs.onopen = () => {
        console.log(`WebSocket open, ${process.env.REACT_APP_WS_ENDPOINT}`);
        setIsWsConnected(true);
      };

      let audioQueue = [];
      let isPlaying = false;
      let audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      let nextStartTime = 0;

      async function playNextChunk() {
        if (isStopped.current === true) {
          audioQueue = [];
          isStopped.current = false;
        }

        if (audioQueue.length === 0) {
          // No more chunks to play
          isPlaying = false;
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
          setIsSpeaking(false);
          return;
        }

        // Get the next chunk from the queue
        let chunk = audioQueue.shift();

        // Create a Blob and an Audio object from the chunk
        let blob = new Blob([chunk], { type: "audio/wav" });
        let response = await fetch(URL.createObjectURL(blob));
        let arrayBuffer = await response.arrayBuffer();
        let audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        source.current = audioContext.createBufferSource();
        source.current.buffer = audioBuffer;

        // Schedule the audio to start at the correct time
        if (nextStartTime < audioContext.currentTime) {
          nextStartTime = audioContext.currentTime;
        }
        source.current.start(nextStartTime);

        // Update the next start time
        nextStartTime += audioBuffer.duration;

        // When the audio finishes playing, play the next chunk
        source.current.onended = playNextChunk;

        // Connect the source to the destination and start playing the audio
        source.current.connect(audioContext.destination);
        isPlaying = true;
      }

      newWs.onmessage = (event) => {
        try {
          if (
            JSON.parse(event.data).sessionId ||
            event.data === "start" ||
            JSON.parse(event.data).metadata
          ) {
            setIsLoading(true);
          }
        } catch (e) {
          if (event.data instanceof Blob) {
            audioQueue.push(event.data);

            // If audio is not currently playing, start playing
            if (!isPlaying) {
              nextStartTime = audioContext.currentTime;
              playNextChunk();
              videoRef.current.play();
              setTimeout(() => setIsSpeaking(true), 300);
            }
          }
          if (event.data === "finish") {
            setIsLoading(false);
          }
        }
      };

      newWs.onerror = (error) => {
        console.log("WebSocket Error:", error);
        setIsWsConnected(false);
        setIsLoading(false);
      };

      setWs(newWs);
    }
  };

  useEffect(() => {
    connectWebSocket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const TIMEOUT = 10000;

  // Create a ping to keep the connection alive every 1 minute
  useEffect(() => {
    if (ws) {
      const pingInterval = setInterval(() => {
        console.log("ping");
        ws.send("ping");
      }, TIMEOUT);

      return () => clearInterval(pingInterval);
    }
  }, [ws]);

  const handleRecordingComplete = (audioBlob) => {
    if (!isWsConnected) {
      console.log("WebSocket in not connected, connect to send audio.");
      return;
    }
    // Converts audioBlob to arrayBuffer
    audioBlob.arrayBuffer().then((buffer) => {
      ws.send(buffer);
    });
  };

  return (
    <>
      <Main
        onRecordingComplete={handleRecordingComplete}
        videoRef={videoRef}
        isLoading={isLoading}
        isSpeaking={isSpeaking}
        isStopped={isStopped}
        source={source}
      />
    </>
  );
};

export default Application;
