import React, { useCallback, useEffect, useRef, useState } from "react";
import { RecordIcon, StopIcon } from "../../assets/icons";
import mainVideo from "../../assets/pinocchio.mp4";
import loaderImage from "../../assets/loader.png";

const formatSeconds = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const paddedMinutes = String(minutes).padStart(2, "0");
  const paddedSeconds = String(remainingSeconds).padStart(2, "0");
  return `${paddedMinutes}:${paddedSeconds}`;
};

const Main = ({
  onRecordingComplete,
  isLoading,
  isSpeaking,
  videoRef,
  isStopped,
  source,
}) => {
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const intervalRef = useRef(null);
  const [recordingTime, setRecordingTime] = useState(0);

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        let audioChunks = [];

        recorder.ondataavailable = (event) => {
          audioChunks.push(event.data);
        };

        recorder.start();
        intervalRef.current = setInterval(() => {
          setRecordingTime((value) => value + 1);
        }, 1000);

        setIsRecording(true);

        recorder.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: "audio/webm" });
          onRecordingComplete(audioBlob);
        };

        setMediaRecorder(recorder);
      })
      .catch((error) =>
        console.error("Errore nella registrazione audio:", error)
      );
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      clearInterval(intervalRef.current);
      setRecordingTime(0);
      setIsRecording(false);
    }
  };

  const handleClick = () => {
    if (isSpeaking) {
      isStopped.current = true;
      source.current.stop();
    } else {
      if (isRecording) {
        stopRecording();
      } else {
        startRecording();
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSpacePress = (event) => {
    if (event.code === "Space" && !isLoading) {
      handleClick();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleSpacePress);
    return () => {
      window.removeEventListener("keydown", handleSpacePress);
    };
  }, [handleSpacePress]);

  const Action = () => {
    switch (true) {
      case isSpeaking:
        return (
          <>
            <div className="waveAnimation">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <StopIcon />
          </>
        );
      case isLoading:
        return (
          <div className="loader">
            <img src={loaderImage} alt="Loading" />
          </div>
        );
      case isRecording:
        return (
          <div className="recordingTime">
            <StopIcon />
            <span>{formatSeconds(recordingTime)}</span>
          </div>
        );
      case !isRecording:
        return <RecordIcon fill={"black"} />;
      default:
        return null;
    }
  };

  return (
    <div className="mainWrapper">
      <video className="video" loop muted ref={videoRef}>
        <source src={mainVideo} type="video/mp4" />
      </video>
      <div className={"actionWrapper"}>
        <div
          className={
            "actionButton " +
            (isRecording ? "recordingTimeWrapper" : "") +
            (isSpeaking ? "waveAnimationWrapper" : "")
          }
          onClick={() => {
            if (!isLoading) {
              handleClick();
            }
          }}
        >
          <Action />
        </div>
      </div>
      <div className="blur" />
    </div>
  );
};

export default Main;
